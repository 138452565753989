import axios from "axios";
import { getBaseUrl, getUrlString } from "./appConfig";
import { useNavigate } from "react-router";

const BASE_URL = getUrlString();

export const loginAPI = async (data) => {
  return axios
    .post(`${BASE_URL}admin/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const sendOtpWithLogin = async (data) => {
  return axios
    .post(`${BASE_URL}admin/login/sendOtp`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const verifyOtpWithLogin = async (data) => {
  return axios
    .post(`${BASE_URL}admin/login/verifyOtp`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};

// API call for AllCategories
export const GetAPI = async (token, path) => {
  return axios
    .get(`${BASE_URL}${path}`, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
// API call for Get Apis
export const GetAPISearch = async (path, token) => {
  return axios
    .get(`${BASE_URL}${path}`, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

// ...........API call for Get Apis.........
export const GetTopicSearch = async (data, path) => {
  return axios
    .get(`${BASE_URL}/category/topic?=${path}=${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.status;
    });
  // return response;
};

// .......API call for Delete........
export const DeleteAPI = async (token, id, path) => {
  return axios
    .delete(`${BASE_URL}${path}${id}`, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.status;
    });
};

// .......API call for Add..........

export const AddCategoryAPI = async (token, name,nameHin, data) => {
  const config = {
    method: "POST",
    headers: {
      accessToken: `${token}`,
    },
    body: data,
  };

  return fetch(`${BASE_URL}category?nameEng=${name}&nameHin=${nameHin}`, config)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Request failed:", error);
      throw error;
    });
};

export const AddTopicAPI = async (token, topicName,topicNameHindi, data, id) => {
  const config = {
    method: "POST",
    headers: {
      accessToken: `${token}`,
    },
    body: data,
  };

  return fetch(
    `${BASE_URL}category/topic?categoryId=${id}&topicNameEng=${topicName}&topicNameHin=${topicNameHindi}`,
    config
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Request failed:", error);
      throw error;
    });
};
// API call for AllCategories
export const UpdateAPI = async (token, path, data) => {
  const config = {
    method: "POST",
    headers: {
      accessToken: `${token}`,
    },
    body: data,
  };

  return fetch(`${BASE_URL}${path}`, config)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Request failed:", error);
      throw error;
    });
};


export const ActiveAPI = async (token, path) => {
  const config = {
    method: "POST",
    headers: {
      accessToken: `${token}`,
    },
  };
  return fetch(`${BASE_URL}${path}`, config)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Request failed:", error);
      throw error;
    });
};

export const GetTopicByCatergoryIdAPI = async (
  token,
  categoryId,
  pageNo,
  pageSize
) => {
  return axios
    .get(`${BASE_URL}admin/topic/categoryId?categoryId=${categoryId}`, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//--------------------POST form Data using axios-------------------

export const postFormData = async (path, body, token) => {
  return getBaseUrl()
    .post(path, body, {
      headers: {
        accessToken: token,
        accept: "*/*"
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//....................Call delete the data APIs......................

export const deleteData = async (token, path) => {
  return getBaseUrl()
    .delete(path, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

// ----------------Used for update method in axios----------------------

export const putRequestAPI = async (token, body, path) => {
  return getBaseUrl()
    .put(path, body, {
      headers: {
        accessToken: token,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

// --------------- Update Api for Path ----------------------------

export const updateContact = async (token, path) => {
  const baseUrl = getBaseUrl();

  try {
    const response = await baseUrl.put(path, null, {
      headers: {
        accessToken: token,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      console.error("No response received from the server");
    } else {
      console.error("Error during request setup:", error.message);
    }

    return null;
  }
};

//-------------------Serach data by ID------------------------

export const searchByID = async (token, path) => {
  return getBaseUrl()
    .get(path, {
      headers: {
        accessToken: `${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};

// ------------------Add Quiz Api ----------------------

export const addQuizApi = async () => {
  const config = {
    method: "POST",
  };
};

// -----------------Add User Api --------------------------

export const addUserApi = async (
  token,
  name,
  emailId,
  mobile,
  password,
  role,
  data
) => {
  const config = {
    method: "POST",
    headers: {
      accessToken: `${token}`,
    },
    body: data,
  };

  return fetch(
    `${BASE_URL}admin/user/add?name=${name}&emailId=${emailId}&mobile=${mobile}&password=${password}&role=${role}`,
    config
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Request failed:", error);
      throw error;
    });
};

// ------------------- multipul delet Api -------------------------

export const deletMultipulApi = async (token, path, data) => {
  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken: token,
      },
      body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}${path}`, config)
      .then((response) => response.json())
      .catch((error) => {
        return error.response.status;
      });
  } catch (error) {
    console.error("Request failed:", error.message);
    throw error;
  }
};

// ---------------------------- Active put Api --------------------------

export const ActivePutAPI = async (token, path) => {
  const config = {
    method: "put",
    url: `${BASE_URL}${path}`,
    headers: {
      accessToken: token,
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Request failed:", error.message);
    throw error;
  }
};
