import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SECRET_KEY } from "../../Utils/Constants";
import { Pagination } from "../../components/Pagination";
import CryptoJS from "crypto-js";
import AddVeersionModal from "../../components/modal/addVeersionModal";
import { versionGetRequest } from "../../Actions";
import { getUrlString } from "../../components/Config/appConfig";
import { toast } from "react-toastify";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";
import { useNavigate } from "react-router";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";

const VersionControl = ({ allVersions, deleteVersionRDCR }) => {
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tversionList, setTransactionArray] = useState([]);

  const getAllVersion = () => {
    dispatch(versionGetRequest({ token, pageNo: currentPage }));
  };

  useEffect(() => {
    getAllVersion();
  }, [isSubmit, currentPage, deleteVersionRDCR]);

  useEffect(() => {
    setTransactionArray(allVersions?.version?.data);
    setTotalPages(allVersions?.version?.data?.totalPages);
    setLoading(allVersions?.loading);
    if (allVersions?.version?.statusCode === 403) {
      window.location.href = "/";
    }
  }, [allVersions]);

  const tableHeaders = [
    { name: "Id" },
    {
      name: "Min version",
      msg: "This is the minimum required version that users should have on their devices.  This version only works with the Force Update flag and is not affected by the Maintenance flag",
    },
    { name: "Device Type" },
    {
      name: "Force Update",
      msg: "If enabled, this flag forces users without the minimum required app version to update their app.",
    },
    {
      name: "Maintenance",
      msg: "If enabled, this flag displays a maintenance popup to users, preventing them from using the app until it is disabled.",
    },
    { name: "Action" },
  ];

  const updateVersion = async (data) => {
    const url = `${getUrlString()}app/updateVersion`;
      const ToastId = toast.loading("Updating ..");


    const options = {
      method: "PUT",
      headers: {
        accessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.statusCode === 200) {
        setIsSubmit((prev) => !prev);
        toast.update(ToastId, {
          render: data?.message,
          type:"success",
          autoClose:2000,
          isLoading:false,
        });
      } else {
      }
      if (data.statusCode === 403) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleMaintenance = async (data) => {
    const url = `${getUrlString()}`;

    const options = {
      method: "PUT",
      headers: {
        accessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.statusCode === 200) {
        setIsSubmit((prev) => !prev);
        toast.success(data?.message);
      } else {
      }
      if (data.statusCode === 403) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 select-none">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] h-[85vh] relative ">
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 pb-3">
            <div className="font-bold uppercase text-lg text-center">
              Version Management
            </div>
          </div>

          {tversionList?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {tableHeaders.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          <div className=" flex gap-1 items-center justify-center">
                            {item.name}
                            {/* {item?.msg && (
                              <span
                                data-tooltip-id="version-tooltip"
                                data-tooltip-place="top-end"
                                data-tooltip-variant="dark"
                                data-tooltip-delay-show={"50"}
                                data-tooltip-html={item?.msg}
                                className=" cursor-pointer "
                              >
                                <IoMdInformationCircleOutline />
                              </span>
                            )} */}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tversionList.map((item, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-6 py-2 text-center">{item.id}</td>
                        {/* <td className="px-6 py- text-center">
                          {item.minVersion}
                        </td> */}
                        <td className="px-6 py- text-center">
                          {item.latestVersion}
                        </td>
                        <td className="px-6 py- text-center">
                          {item.deviceType}
                        </td>
                        <td className="px-6 py-2 text-center">
                          {item.forceUpdateEnabled ? (
                            <button
                              disabled={!checkAccess("VERSIONCONTROL", "U")}
                              onClick={() => {
                                updateVersion({
                                  ...item,
                                  forceUpdateEnabled: false,
                                });
                              }}
                              type="button"
                              className={`inline-flex items-center px-5 border-green-200 border-2 py-2.5 text-sm font-medium text-center text-green-700 rounded-lg hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-100  ${
                                !checkAccess("VERSIONCONTROL", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              Enabled
                            </button>
                          ) : (
                            <button
                              disabled={!checkAccess("VERSIONCONTROL", "U")}
                              onClick={() => {
                                updateVersion({
                                  ...item,
                                  forceUpdateEnabled: true,
                                });
                              }}
                              type="button"
                              className={`inline-flex border-red-200 border-2 items-center px-5 py-2.5 text-sm font-medium text-center text-red-400 rounded-lg hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-100 ${
                                !checkAccess("VERSIONCONTROL", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              Disabled
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-2 text-center">
                          {item.maintenanceModeEnabled ? (
                            <button
                              disabled={!checkAccess("VERSIONCONTROL", "U")}
                              onClick={() => {
                                updateVersion({
                                  ...item,
                                  maintenanceModeEnabled: false,
                                });
                              }}
                              type="button"
                              className={`inline-flex items-center px-5 border-green-200 border-2 py-2.5 text-sm font-medium text-center text-green-700 rounded-lg hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-100  ${
                                !checkAccess("VERSIONCONTROL", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              Enabled
                            </button>
                          ) : (
                            <button
                              disabled={!checkAccess("VERSIONCONTROL", "U")}
                              onClick={() => {
                                updateVersion({
                                  ...item,
                                  maintenanceModeEnabled: true,
                                });
                              }}
                              type="button"
                              className={`inline-flex border-red-200 border-2 items-center px-5 py-2.5 text-sm font-medium text-center text-red-400 rounded-lg hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-100 ${
                                !checkAccess("VERSIONCONTROL", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              Disabled
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-2 text-center">
                          <button
                            type="submit"
                            disabled={!checkAccess("VERSIONCONTROL", "U")}
                            onClick={() => {
                              setShowModal(true);
                              setEditItem(item);
                            }}
                            className={`w-auto text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                              !checkAccess("VERSIONCONTROL", "D")
                                ? "accessDenied"
                                : "hover:cursor-pointer"
                            }`}
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={allVersions?.loading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Versions Not Found
            </div>
          )}
          {showModal && (
            <AddVeersionModal
              prefillData={editItem}
              setShowModal={setShowModal}
              callSubmit={updateVersion}
            />
          )}
        </div>
      </div>
      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="version-tooltip"
        className=" font-semibold"
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    allVersions: state?.VersionGetReducer,
    deleteVersionRDCR: state?.VersionDeleteReducer,
  };
};

export default connect(mapStateToProps)(VersionControl);
