import React, { useCallback, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { useDispatch, connect } from "react-redux";
import { SECRET_KEY } from "../../Utils/Constants";
import { WinnerBreakupModal } from "../modal/WinnerBreakupModal";
import {
  CategoriesRequest,
  activeQuizStatus,
  cancelQuizRequiest,
  deleteQuizRequest,
  getCommissionRateRequest,
  getLeaderboardRequest,
  getLeaderboardSearchByNameRequest,
  getLeaderbordUserQuestionReset,
  updateQuizRequest,
} from "../../Actions";
import {
  formatDateAndTime,
  totalWinning,
  winnerBreakup,
} from "../createQuiz/calculation";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Tabs from "../ui-elements/tabs";
import { MdArrowBack, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
import { DeleteModal } from "../modal/DeleteModal";
import { ConfirmationModal } from "../modal/ConfirmationModal";
import { activeQuizStatusEmpty } from "../../Actions/ActiveQuizeStatusAction";
import { checkAccess } from "../../Utils/RoleBasedRoute";

const Input = ({
  label,
  register,
  required,
  name,
  type,
  totalWinnings,
  value,
  disabled,
  hiden,
  onFocus,
}) => (
  <>
    <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
      {label}
    </label>
    <input
      {...register(name, { required })}
      type={type}
      name={name}
      value={value}
      placeholder={label}
      onChange={totalWinnings}
      disabled={disabled}
      hidden={hiden}
      onFocus={onFocus}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2  font-medium "
    />
  </>
);

const ViewQuiz = ({ leaderboard, item, commissionRate, cancleQuizState ,removeAssignedQuestions}) => {
  const CR = commissionRate?.commissionRate?.data?.rateInPercent;
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const dispatch = useDispatch();
  const [completedCancelled, setCompletedCancelled] = useState(false);
  const [openQuizAddModal, setOpenQuizAddModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [winingData, setWiningData] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [leaderboardArray, setLeaderboardArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [operation, setOperation] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeleteQuizModal, setOpenDeleteQuizModal] = useState(false);
  const [deleteQuizData, setDeleteQuizData] = useState({});
  const [cancelQuizData, setCancelQuiz] = useState();
  const { searchLeaderboard } = leaderboard;
  const timerRef = useRef(null);

  const {
    duration,
    entryFees,
    filledSpots,
    id,
    isEnabled,
    isLive,
    noOfQuestions,
    startTime,
    topicId,
    totalSpots,
    totalWinnings,
    winnerDistribution,
    quizStatus,
    isCompleted,
    frequency,
    isPrime,
    isBotAllowed,
    isSpecial,
    isCancelled,
    isReviewing,
    isPractice,
    categoryId,
    categoryNameEng,
    topicNameEng,
  } = item;
  console.log(item, "view quiz item");
  const [entryFees1, setEntryFees] = useState(entryFees);
  const [totalSpots1, setTotalSpots] = useState(totalSpots);
  const [isDuration, setIsDuration] = useState(duration);
  const [totalWinningAmount, setTotalWinningAmount] = useState(totalWinnings);
  const [totalAmountOfWinning, setTotalAmountOfWinning] =
    useState(totalWinnings);
  const [totalWinningAmount1, setTotalWinningAmount1] =
    useState(totalWinningAmount);
  const [winningMessage, setWinningMessage] = useState(false);
  const dateString = startTime === "IMMEDIATELY" ? "" : startTime;
  const isPractise = startTime === "IMMEDIATELY" ? true : false;
  const navigate = useNavigate();
  let formattedDate = "";
  if (dateString) {
    const parsedDate = parse(dateString, "dd-MM-yyyy HH:mm:ss", new Date());
    formattedDate = format(
      parsedDate,
      "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx (zzz)"
    );
  } else {
    console.error("Date string is empty");
  }
  const formatChange = formattedDate.split("GMT");
  const currectDate = formatChange[0] + "GMT+0530 (India Standard Time)";
  const [editTime, setEditTime] = useState(new Date(currectDate));
  const formatedDate = formatDateAndTime(editTime);
  const [editMode, setEditMode] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);

  useEffect(() => {
    dispatch(CategoriesRequest({ token }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(activeQuizStatusEmpty());
    };
  }, []);

  useEffect(() => {
    setCompletedCancelled(isCompleted || isCancelled || isLive || isReviewing);
  }, [item]);

  const { register, handleSubmit, setValue } = useForm();

  const handleActive = (boolean) => {
    setOperation(`${boolean ? "Enable" : "Disable"}`);
    setOpenCancelModal(true);
    setCancelQuiz({ data: topicId, boolean });
  };

  const handleDeleteQuiz = (quizId) => {
    const body = {
      token: token,
      quizId,
    };
    dispatch(deleteQuizRequest(body));
    navigate(-1);
  };

  const getCommissionRate = () => {
    const body = {
      token: token,
    };
    dispatch(getCommissionRateRequest(body));
  };

  const handleCancelQuiz = (id) => {
    setOperation("cancel");
    setOpenCancelModal(true);
    setCancelQuiz({ data: id });
  };

  const handleQuizConfirmed = (data) => {
    if (operation === "cancel") {
      const body = {
        token: token,
        id: id,
      };
      dispatch(cancelQuizRequiest(body));
    } else if (operation === "Enable" || operation === "Disable") {
      const body = {
        token: token,
        isEnabled: cancelQuizData?.boolean,
        topicId: topicId,
        id: id,
      };
      dispatch(activeQuizStatus(body));
    }
    setOperation("");
    setCancelQuiz(null);
  };

  useEffect(() => {
    if (cancleQuizState.CancelQuiz.statusCode === 200) {
      navigate("/quizzes");
    }
  }, [cancleQuizState.CancelQuiz.statusCode, navigate]);

  const handleWinnerDistribution = () => {
    setOpenQuizAddModal(true);
  };

  const handlePopUpModel = () => {
    setEditMode(true);
  };

  const winningBreackup = useCallback(() => {
    const body = {
      totalWinningAmount: editMode
        ? isSpecial
          ? totalAmountOfWinning
          : totalWinningAmount1
        : totalAmountOfWinning,
      quizMode: winnerDistribution?.quizMode,
      totalSpots: totalSpots1,
    };
    const BW = winnerBreakup(body);
    return BW;
  }, [
    totalWinningAmount1,
    totalAmountOfWinning,
    winnerDistribution?.quizMode,
    totalSpots1,
  ]);

  const [winnerBreakdownData, setWinnerBreakdownData] = useState(
    winningBreackup()
  );

  useEffect(() => {
    setValue(
      "totalWinnings",
      totalWinning(entryFees1, totalSpots1, commissionRate)
    );
    setTotalWinningAmount(
      totalWinning(entryFees1, totalSpots1, commissionRate)
    );
    const updatedWinnerBreakdown = winningBreackup(
      entryFees1,
      totalSpots1,
      editMode
        ? isSpecial
          ? totalAmountOfWinning
          : totalWinningAmount
        : totalAmountOfWinning,
      winnerDistribution?.quizMode
    );
    setWinnerBreakdownData(updatedWinnerBreakdown);
  }, [
    entryFees1,
    totalSpots1,
    totalWinningAmount,
    winnerDistribution?.quizMode,
    editMode,
    isSpecial,
    totalAmountOfWinning,
  ]);

  const totalWin = (e) => {
    const { name, value } = e.target;
    if (name === "duration") {
      setIsDuration(value);
    } else {
      setEditMode(true);
      if (name === "entryFees") {
        setEntryFees(value);
      } else if (name === "totalSpots") {
        setTotalSpots(value);
      }
    }

    const updatedWinnerBreakdown = winningBreackup(
      entryFees1,
      totalSpots1,
      totalWinningAmount,
      winnerDistribution.quizMode
    );
    setWinnerBreakdownData(updatedWinnerBreakdown);
    setWiningData({
      ...winingData,
      [name]: value,
    });
  };

  const onSubmit = (data) => {
    if (checkAccess("QUIZ", "U")) {
      if (editTime < new Date() && !isPractise) {
        toast.warning(
          "Start time of the quiz should be greater than current time"
        );
      } else {
        const body = {
          token: token,
          data: {
            ...data,
            startTime: isPractise ? "IMMEDIATELY" : formatedDate,
            commissionPercentage: CR,
            topicId: topicId,
            id: id,
            winners: winnerBreakdownData?.totalWinner,
            winnerDistribution: {
              quizMode: winnerDistribution?.quizMode,
              distribution: winningBreackup(
                data.entryFees,
                data.totalSpots,
                isSpecial ? totalAmountOfWinning : totalWinningAmount,
                winnerDistribution?.quizMode
              ).numberOfWinner,
            },
          },
        };
        dispatch(updateQuizRequest(body));
        setOpenQuizAddModal(false);
      }
    }
  };

  const getData = () => {
    const body = {
      token,
      id: id,
      pageNo: currentPage,
    };

    const bodyForUpcoming = {
      token,
      id: id,
      pageNo: currentPage,
      joiners: true,
    };

    dispatch(
      getLeaderboardRequest(quizStatus == "Upcoming" ? bodyForUpcoming : body)
    );
  };



  useEffect(() => {
    getData();
  }, [currentPage, removeAssignedQuestions]);

  useEffect(() => {
    if (leaderboard?.loading) {
      setIsLoading(true);
    }
    if (leaderboard?.error) {
      setIsLoading(false);
    }
    console.log(leaderboard?.leaderboard?.data,'upcoming users data ')
    if (leaderboard?.leaderboard?.data?.leaderboard) {
      setLeaderboardArray(leaderboard?.leaderboard?.data.leaderboard);
      setIsLoading(false);
    }
    if (leaderboard?.leaderboard?.data?.users) {
      setLeaderboardArray(leaderboard?.leaderboard?.data.users);
      setIsLoading(false);
    }
    if (searchValue && searchLeaderboard?.data) {
      setLeaderboardArray(searchLeaderboard?.data);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (searchLeaderboard?.data?.statusCode === 404 ||
        searchLeaderboard?.data?.statusCode === 400)
    ) {
      setLeaderboardArray([]);
      setIsLoading(false);
    }

    if (leaderboard?.leaderboard?.data?.totalPages) {
      setTotalPages(leaderboard?.leaderboard?.data?.totalPages);
    }
  }, [leaderboard, searchLeaderboard]);

  useEffect(() => {
    dispatch(getLeaderbordUserQuestionReset());
  }, []);

  const getSearchLeaderboard = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
    }

    const body = {
      token,
      id: id,
      userName: searchParam || event?.target.value,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      if (body?.userName) {
        dispatch(getLeaderboardSearchByNameRequest(body));
      } else {
        return;
      }
    }, 10);
  };

  useEffect(() => {
    getSearchLeaderboard();
    getCommissionRate();
  }, []);

  const handleDateChange = (newDate) => {
    setEditTime(newDate);
  };

  let calculateTotalWin =
    totalSpots1 * entryFees1 - (totalSpots1 * entryFees1 * CR) / 100;
  const handleTotalWinningsChange = (e) => {
    const value = e?.target?.value;
    if (isSpecial) {
      setTotalAmountOfWinning(value);
    } else if (value <= calculateTotalWin) {
      setTotalWinningAmount1(value);
    } else {
      setTotalAmountOfWinning(value);
      setTotalWinningAmount(value);
    }
  };

  const renderBadge = (text, color = "green") => (
    <div
      className={`flex self-end bg-${color}-500 text-white px-3 py-1 font-medium text-sm rounded-md`}
    >
      {text}
    </div>
  );

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] relative">
          <div className="flex justify-between">
            <div className="cursor-pointer">
              <span
                onClick={() => navigate(-1)}
                className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
              >
                <MdArrowBack className="text-base" /> Back
              </span>
            </div>
            <div
              onClick={() => {
                if (checkAccess("QUIZ", "D")) {
                  setDeleteQuizData({
                    name: `Quiz with id ${item.id}`,
                    id: item.id,
                  });
                  setOpenDeleteQuizModal(true);
                  setSearchValue("");
                }
              }}
              className={`hover:bg-gray-100  p-2 rounded-full ${
                !checkAccess("QUIZ", "D")
                  ? "accessDenied"
                  : "hover:cursor-pointer"
              }`}
            >
              <MdDelete className="text-2xl" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
            <div className="font-bold uppercase text-lg text-center flex flex-col md:flex-row gap-4 items-center">
              {`View Quiz ID #${id}`}
              {winnerDistribution?.quizMode &&
                renderBadge(winnerDistribution?.quizMode)}
              <>
                {isEnabled && isLive && renderBadge("Live Now!")}
                {isPrime && renderBadge("Prime")}
                {isSpecial && renderBadge("Special")}
                {isBotAllowed && renderBadge("Add Bots")}
                {isPractice && renderBadge("Practice")}
                {frequency && renderBadge(frequency)}
              </>
            </div>
          </div>
          <div className="flex flex-col md:flex-row my-2 gap-2 justify-between">
            <div className="flex flex-col items-center md:w-full lg:w-1/3">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Quiz Type
              </label>
              <div className="py-2 font-bold">
                {entryFees
                  ? "Cash Quiz"
                  : `${isPractice ? "Practice Quiz" : "Free Quiz"}`}
              </div>
            </div>
            {item?.flage === "userPlayedQuiz" ? null : (
              <div className="flex flex-col items-center md:w-full lg:w-1/3">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Actions
                </label>
                {isEnabled ? (
                  <button
                    disabled={completedCancelled || !checkAccess("QUIZ", "U")}
                    onClick={() => {
                      handleActive(false);
                    }}
                    type="button"
                    className={`${
                      completedCancelled
                        ? "border-gray-200 text-gray-700 focus:ring-gray-100 cursor-not-allowed"
                        : "border-green-200 text-green-700 focus:ring-green-100 hover:bg-green-50"
                    } inline-flex items-center  border-2 px-3 py-2 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none ${
                      !checkAccess("QUIZ", "U")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  >
                    Enabled
                  </button>
                ) : (
                  <button
                    disabled={completedCancelled || !checkAccess("QUIZ", "U")}
                    onClick={() => {
                      handleActive(true);
                    }}
                    type="button"
                    className={`inline-flex items-center px-3 py-2 ${
                      completedCancelled
                        ? "border-gray-200 text-gray-700 focus:ring-gray-100 cursor-not-allowed"
                        : "border-red-200 text-red-700 focus:ring-red-100 hover:bg-red-50"
                    } border-2 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none ${
                      !checkAccess("QUIZ", "U")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  >
                    Disabled
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-col items-center md:w-full lg:w-1/3">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Status
              </label>
              <div
                type="button"
                className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-green-700 rounded-lg focus:ring-4 focus:outline-none focus:ring-green-100"
              >
                {quizStatus}
              </div>
            </div>
            <div className="flex flex-col items-center md:w-full lg:w-1/3">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Filled Spots
              </label>
              <div
                type="button"
                className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-green-700 rounded-lg focus:ring-4 focus:outline-none focus:ring-green-100"
              >
                {filledSpots}
              </div>
            </div>
            {item?.flage === "userPlayedQuiz" || completedCancelled ? null : (
              <div className="md:w-full flex justify-center lg:w-1/3 mt-4 lg:mt-0">
                <button
                  className={`text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 ${
                    !checkAccess("QUIZ", "U")
                      ? "accessDenied"
                      : "hover:cursor-pointer"
                  }`}
                  disabled={!checkAccess("QUIZ", "U")}
                  type="button"
                  onClick={() => {
                    handleCancelQuiz(id);
                  }}
                >
                  Cancel Quiz
                </button>
              </div>
            )}
          </div>
          <form className="space-y-6 mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mb-4 sm:grid-cols-2 lg:grid-cols-3">
              <div>
                <Input
                  disabled={
                    isLive ||
                    isReviewing ||
                    !checkAccess("QUIZ", "U") ||
                    isPractise
                  }
                  type="number"
                  name="entryFees"
                  label="Entry Fees"
                  register={register}
                  totalWinnings={totalWin}
                  value={editMode ? entryFees1 : entryFees}
                  onFocus={() => setConfirmModel(true)}
                />
              </div>
              <div>
                <Input
                  disabled={
                    isLive ||
                    isReviewing ||
                    !checkAccess("QUIZ", "U") ||
                    isPractise
                  }
                  type="number"
                  name="totalSpots"
                  label="Total Spots"
                  register={register}
                  totalWinnings={totalWin}
                  onFocus={() => setConfirmModel(true)}
                  value={editMode ? totalSpots1 : totalSpots}
                />
              </div>
              <div>
                <Input
                  disabled={
                    isLive ||
                    isReviewing ||
                    !checkAccess("QUIZ", "U") ||
                    isPractise
                  }
                  type="number"
                  name="totalWinnings"
                  label="Total Winnings Amount"
                  register={register}
                  value={
                    editMode
                      ? isSpecial
                        ? totalAmountOfWinning
                        : totalWinningAmount1
                      : totalAmountOfWinning
                  }
                  onFocus={() => {
                    setWinningMessage(true);
                    setConfirmModel(true);
                  }}
                  totalWinnings={(e) => handleTotalWinningsChange(e)}
                />
              </div>
              <div>
                <Input
                  // disabled={isLive || isReviewing || !checkAccess("QUIZ", "U")}
                  disabled={true}
                  name="noOfQuestions"
                  label="Total number of questions"
                  value={noOfQuestions}
                  register={register}
                />
              </div>
              <div>
                <Input
                  disabled={isLive || isReviewing || !checkAccess("QUIZ", "U")}
                  name="duration"
                  label="Quiz Duration"
                  value={isDuration}
                  register={register}
                  totalWinnings={totalWin}
                />
              </div>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                  {"Start Time"}
                </label>
                {startTime === "IMMEDIATELY" ? (
                  <>
                    <input
                      type="text"
                      value={"IMMEDIATELY"}
                      disabled={true}
                      autoComplete="off"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2 font-medium`}
                    />
                  </>
                ) : (
                  <DatePicker
                    disabled={
                      isLive || isReviewing || !checkAccess("QUIZ", "U")
                    }
                    selected={editTime}
                    onChange={handleDateChange}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy HH:mm:ss"
                    minDate={new Date()}
                    className="custom-date-picker bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  mb-1 md:mb-2  font-medium"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              {!isLive && !isReviewing && !isCompleted && (
                <div className="w-full md:w-1/4">
                  <button
                    className={`w-full text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                      !checkAccess("QUIZ", "U")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  >
                    <span className="inline-block md:inline-block">
                      {"Save Quiz"}
                    </span>
                  </button>
                </div>
              )}
              <div className="mt-[-10px]">
                <button
                  onClick={() => {
                    handleWinnerDistribution();
                  }}
                  type="button"
                  className="sm:w-full md:mt-4 lg:mt-4 sm:mt-0 text-left text-blue-500"
                >
                  <span className="inline-block">Winners breakup</span>
                </button>
              </div>
            </div>
          </form>
          <div className="mt-10">
            <Tabs
              quizStatus={quizStatus}
              itemsArray={leaderboardArray}
              searchLeaderboard={searchLeaderboard}
              getSearchLeaderboard={getSearchLeaderboard}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isLoading}
              searchValue={searchValue}
              isLive={isLive}
              getData={getData}
              navigate={navigate}
              id={id}
              token={token}
              setSearchValue={setSearchValue}
              categoryId={categoryId}
              categoryNameEng={categoryNameEng}
              topicId={topicId}
              topicNameEng={topicNameEng}
            />
          </div>
        </div>
      </div>
      {confirmModel && !editMode && (
        <DeleteModal
          data={
            winningMessage
              ? "Editing this value will update the Winner Breakup calculation. Are you sure you want to continue. ? "
              : "Editing this value will update the Winner Breakup calculation according to the current commission rate. Are you sure you want to continue. ?"
          }
          open="textField"
          handleDelete={handlePopUpModel}
          setOpenDeleteModal={setConfirmModel}
        />
      )}

      {openQuizAddModal && (
        <WinnerBreakupModal
          heading={"Show Breackup of Quiz"}
          nameLabel={"New Quiz"}
          submitLabel={"Submit"}
          submitLabelsm={"Save Changes"}
          openModal={openQuizAddModal}
          setOpenModal={setOpenQuizAddModal}
          data={winningBreackup()}
        />
      )}

      {openDeleteQuizModal && (
        <DeleteModal
          data={deleteQuizData}
          handleDelete={handleDeleteQuiz}
          setOpenDeleteModal={setOpenDeleteQuizModal}
        />
      )}
      {openCancelModal && (
        <ConfirmationModal
          operation={operation}
          data={cancelQuizData?.data}
          source={""}
          handleCancel={handleQuizConfirmed}
          setOpenCancelModal={setOpenCancelModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leaderboard: state?.leaderboardReducer,
    removeAssignedQuestions:state?.RemoveAssignedQuestionsReducer,
    selectQuestion: state?.selectQuestionReducer,
    selectQuestionByQuizId: state?.selectQuestionByQuizIdReducer,
    updateQuiz: state?.updateQuizReducer?.updateQuiz?.data,
    userQuestions: state?.leaderboardUserQuestionReducer,
    commissionRate: state?.commissionRatesReducer,
    cancleQuizState: state?.CancelQuizReducer,
  };
};

export default connect(mapStateToProps)(ViewQuiz);
