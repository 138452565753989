import React, { useState, useEffect, useCallback } from "react";
import { CreateQuiz } from "../components/createQuiz";
import { useForm } from "react-hook-form";
import { SECRET_KEY } from "../Utils/Constants";
import CryptoJS from "crypto-js";
import { connect, useDispatch } from "react-redux";
import {
  CategoriesRequest,
  getTopicsByCategoryIdRequest,
  addQuizRequiest,
  addQuizReset,
  getCommissionRateRequest,
  getQuizSpotPercentageRequest,
} from "../Actions";
import {
  winnerBreakup,
  totalWinning,
  formatDateAndTime,
} from "../components/createQuiz/calculation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function CreateQuizScreen(props) {
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const getDate15MinutesFromNow = () => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    return date;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [topicId, setTopicId] = useState();
  const [winingData, setWiningData] = useState([{}]);
  const [totalWinningAmount, setTotalWinningAmount] = useState(0);
  const [startTime, setStartTime] = useState(
    formatDateAndTime(getDate15MinutesFromNow())
  );
  const [quizMode, setQuizMod] = useState("Classic");
  const [openQuizAddModal, setOpenQuizAddModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [checked, setCheked] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [startTimeChanged, setStartTimeChanged] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [cashChecked, setCashChecked] = useState(true);
  const [botChecked, setBotChecked] = useState(false);
  const [specialChecked, setSpecialChecked] = useState(false);
  const [freeChecked, setFreeChecked] = useState(false);
  const [isPrime, setIsPrime] = useState(false);
  const [isDaily, setIsDaily] = useState(false);
  const [isWeekly, setIsWeekly] = useState(false);
  const [isPractice, setIsPractice] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [submited, setSubmited] = useState(false);
  const [minUser, setMinUser] = useState(0);
  const [entryFees, setEntryFee] = useState(0);
  const [specialQuizUsers, setSpecialQuizUsers] = useState(0);

  const topic = props?.TopicsByCategoryId.topics.topics;
  const categories = props?.allCategoriesProp?.AllCategories?.categories;
  const CR = props?.commissionRate?.commissionRate?.data?.rateInPercent;
  const QSPR =
    props?.quizSpotPercentageRate?.quizSpotPercentageRate?.data
      ?.requiredSpotPercent;

  useEffect(() => {
    dispatch(CategoriesRequest({ token }));
  }, []);

  const form = useForm({
    defaultValues: async () => {
      return {
        entryFees: "",
        totalSpots: "",
        totalWinnings: "",
        noOfQuestions: "",
        duration: "",
        repeatInterval: "",
      };
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isSubmitSuccessful },
  } = form;

  const winningBreackup = useCallback(() => {
    const body = {
      totalWinningAmount: totalWinningAmount,
      quizMode: quizMode,
      totalSpots: winingData.totalSpots,
    };
    const BW = winnerBreakup(body);
    return BW;
  }, [totalWinningAmount, quizMode, winingData.totalSpots]);

  const handleStartTimeChange = (date) => {
    const currentDate = new Date();
    if (date < currentDate) {
      toast.info("Start time of the quiz should be greater than current time");
    } else {
      setStartTimeChanged(true);
    }
    setStartTime(formatDateAndTime(date));
  };

  function handleIsReapting() {
    setIsRepeating(!isRepeating);
  }

  function handleIsEnbled() {
    setIsEnabled(!isEnabled);
  }

  useEffect(() => {
    if (specialChecked) {
      dispatch(getQuizSpotPercentageRequest({ token }));
    } else {
      dispatch(getCommissionRateRequest({ token }));
    }
  }, [winingData.entryFees, winingData.totalSpots]);

  useEffect(() => {
    return () => {
      dispatch(addQuizReset());
    };
  }, []);

  const onSubmit = (data) => {
    const easyCount = parseInt(data.easyCount) || 0;
    const mediumCount = parseInt(data.mediumCount) || 0;
    const difficultCount = parseInt(data.difficultCount) || 0;
    const easyImageCount = parseInt(data?.easyImageCount) || 0;
    const mediumImageCount = parseInt(data?.mediumImageCount) || 0;
    const difficultImageCount = parseInt(data?.difficultImageCount) || 0;
    const totalQuestions = easyCount + mediumCount + difficultCount + easyImageCount + mediumImageCount + difficultImageCount;
    // const totalQuestions = easyCount + mediumCount + difficultCount ;

    const rate = specialChecked ? QSPR : CR;
    if (topicId === undefined) {
      toast.info("Please select a valid topic.");
    } else if (data.entryFees < 0) {
      toast.warning("Please enter a positive number for entry fees");
    } else if (totalQuestions != data.noOfQuestions) {
      toast.info(
        "Count of easy, medium and difficult questions should be equal to number of questions in Quiz"
      );
    } else if (isRepeating && !isDaily && !isWeekly && !isPractice) {
      toast.info("Please choose either Daily or Weekly for a repeating quiz");
    } else {
      setSubmited(true);
      const body = {
        data: {
          ...data,
          topicId: topicId,
          startTime: isPractice ? "IMMEDIATELY" : startTime,
          winners: data.entryFees == "0" ? 0 : winningBreackup().totalWinner,
          filledSpots: 0,
          isJoined: false,
          isLive: false,
          isCompleted: false,
          isReviewing: false,
          isCancelled: false,
          isRepeating: isRepeating,
          repeatInterval: `${data.repeatInterval} mins`,
          isEnabled: isEnabled,
          frequency: frequency,
          isPrime: isPrime,
          isSpecial: specialChecked,
          isBotAllowed: botChecked,
          commissionPercentage: CR,
          winnerDistribution: freeChecked
            ? undefined
            : {
                quizMode: quizMode,
                distribution: winningBreackup().numberOfWinner,
              },
        },
        token: token,
      };
      dispatch(addQuizRequiest(body));
      setIsFormSubmitted(true);
    }
  };

  if (props.AddQuiz.status === 201) {
    navigate("/quizzes");
  } else {
  }

  const handleSortValues = (id, value) => {
    if (id === "categoryOptions") {
      getTopic(value);
    } else if (id === "topicOptions") {
      setTopicId(value);
    } else if (id === "quizModeOptions") {
      setQuizMod(value);
    }
  };

  const getTopic = (cat) => {
    const body = {
      token: token,
      categoryId: cat,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  };

  useEffect(() => {
    setValue(
      "totalWinnings",
      totalWinning(
        winingData.entryFees,
        winingData.totalSpots,
        specialChecked ? props.quizSpotPercentageRate : props.commissionRate,
        specialChecked,
      )
    );
    setTotalWinningAmount(
      totalWinning(
        winingData.entryFees,
        winingData.totalSpots,
        specialChecked ? props.quizSpotPercentageRate : props.commissionRate,
        specialChecked,
      )
    );
  }, [
    winingData.entryFees,
    winingData.totalSpots,
    props.commissionRate,
    props.quizSpotPercentageRate,
  ]);

  const totalWinnings = (e) => {
    const { name, value } = e.target;
    if (name === "entryFees") {
      setEntryFee(value);
    }
    setWiningData({
      ...winingData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (totalWinningAmount && entryFees) {
      setMinUser(Math.ceil(totalWinningAmount / entryFees));
    } else {
      setMinUser(0);
    }
    if (winingData?.totalSpots && winingData.totalSpots > 0 && QSPR) {
      setSpecialQuizUsers(Math.ceil((winingData.totalSpots * QSPR) / 100));
    } else {
      setSpecialQuizUsers(0);
    }
  }, [totalWinningAmount, entryFees, winingData.totalSpots, QSPR]);

  const checkTotalSpot = () => {
    if (
      parseInt(winingData.totalSpots) / 2 < 1000 &&
      quizMode === "Mega contest"
    ) {
      toast.info("The mega contest requires a above of 1000 winners.");
    } else if (
      parseInt(winingData.totalSpots) > 1000 &&
      quizMode === "Equal distribution"
    ) {
      setOpenQuizAddModal(true);
    } else if (
      parseInt(winingData.totalSpots) / 2 > 1000 &&
      quizMode !== "Mega contest"
    ) {
      toast.info(
        "winning amount is more than 1000 please select 'mega contests(over 1000 spots)' option."
      );
    } else {
      setOpenQuizAddModal(true);
    }
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <CreateQuiz
      categories={categories}
      topics={topic}
      toggleDetails={toggleDetails}
      checkTotalSpot={checkTotalSpot}
      handleStartTimeChange={(date) => handleStartTimeChange(date)}
      handleSortValues={handleSortValues}
      register={register}
      watch={watch}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      openQuizAddModal={openQuizAddModal}
      setOpenQuizAddModal={setOpenQuizAddModal}
      winningBreackup={winningBreackup}
      totalWinnings={totalWinnings}
      setQuizMod={setQuizMod}
      showDetails={showDetails}
      setValue={setValue}
      setTotalWinningAmount={setTotalWinningAmount}
      isSubmitSuccessful={isSubmitSuccessful}
      topicId={topicId}
      handleIsReapting={handleIsReapting}
      handleIsEnbled={handleIsEnbled}
      isRepeating={isRepeating}
      setIsRepeating={setIsRepeating}
      isEnabled={isEnabled}
      setIsEnabled={setIsEnabled}
      isFormSubmitted={isFormSubmitted}
      totalWinningAmount={totalWinningAmount}
      winingData={winingData}
      cashChecked={cashChecked}
      botChecked={botChecked}
      specialChecked={specialChecked}
      freeChecked={freeChecked}
      setCashChecked={setCashChecked}
      setBotChecked={setBotChecked}
      setSpecialChecked={setSpecialChecked}
      setFreeChecked={setFreeChecked}
      isPrime={isPrime}
      setIsPrime={setIsPrime}
      isDaily={isDaily}
      setIsDaily={setIsDaily}
      isWeekly={isWeekly}
      setIsWeekly={setIsWeekly}
      setFrequency={setFrequency}
      AddQuiz={props.AddQuiz}
      submited={submited}
      minUser={minUser}
      isPractice={isPractice}
      setIsPractice={setIsPractice}
      specialQuizUsers={specialQuizUsers}
      getValues={getValues}
      cr={CR}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
    allCategoriesProp: state?.AllCategoriesReducer,
    AddQuiz: state?.AddQuizReducer,
    commissionRate: state?.commissionRatesReducer,
    quizSpotPercentageRate: state?.quizSpotPercentagesReducer,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuizScreen);
