import { FaPlus } from "react-icons/fa";
import { Search } from "../components/Search";
import { MdModeEditOutline, MdRefresh } from "react-icons/md";
import { Button } from "../components/ui-elements/Button";
import { Select } from "../components/select/SelectNew";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import { getContactUSAllRequest } from "../Actions";
import { Pagination } from "../components/Pagination";
import DescriptionModal from "../components/modal/descriptionModal";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../components/constant";
import { updateContactUsEmpty } from "../Actions/UpdateContactUsAction";
import Loading from "../components/loading";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../Utils/RoleBasedRoute";

const CoustomerSuport = ({ conatctUs, assignee, updateContactUs }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterArea, setShowFilterArea] = useState(true);
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("customerSortValue") || ""
  );
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitial, setShowInitial] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("customerCurrentPage")) || 1
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  // const [isSearchDropdown, setIsSearchDropdown] = useState("");
  const [isSearchDropdown, setIsSearchDropdown] = useState(
    localStorage.getItem("customerSearchDropdowne") || ""
  );
  // const [searchValue, setSearchValue] = useState("");
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("customerSearchValue") || ""
  );
  const [customerQuires, setCoustomerQuire] = useState([]);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const [openAssigneeModal, setOpenAssigneeModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const timerRef = useRef(null);
  const [assigneId, setAssigneId] = useState("");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [openStatusModel, setOpenStatusModel] = useState(false);
  const [desabledSerchBox, setDesabledSerchBox] = useState(true);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const dropdownOptions = [
    {
      id: "",
      name: "Recent",
    },
    {
      id: "UNASSIGNED",
      name: "by unassigned",
    },
    {
      id: "IN_PROGRESS",
      name: "in-progress",
    },
    {
      id: "UNDER_REVIEW",
      name: "under review",
    },
    {
      id: "COMPLETED",
      name: "completed",
    },
  ];

  const serchDropDown = [
    {
      id: "",
      name: "Search By",
    },
    {
      id: "name",
      name: "customer name",
    },
    {
      id: "assigneeName",
      name: "assignee name",
    },
  ];

  const tableHeaders = [
    "id",
    "name",
    // "Countery Code",
    "phone",
    "email",
    "date/time",
    "description",
    "notes",
    "assign",
    "status",
    "Action",
  ];

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const getCoustomer = () => {
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getContactUSAllRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    if (updateContactUs?.updateContactUs?.statusCode === 200) {
      setOpenAssigneeModal(false);
      const body = {
        token,
        pageNo: currentPage,
      };
      dispatch(getContactUSAllRequest(body));
    }
  }, [updateContactUs]);

  useEffect(() => {
    getCoustomer();
  }, [currentPage, openAssigneeModal, openStatusModel]);

  useEffect(() => {
    if (conatctUs?.loading) {
      setIsLoading(true);
    }
    if (conatctUs?.error) {
      setIsLoading(false);
    }

    if (conatctUs?.conatctUs?.data?.queries) {
      setCoustomerQuire(conatctUs?.conatctUs?.data?.queries);
      setIsLoading(false);
    }

    if (searchValue && conatctUs?.data) {
      setCoustomerQuire([conatctUs?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (conatctUs?.conatctUs?.data?.statusCode === 404 ||
        conatctUs?.conatctUs?.data?.statusCode === 400)
    ) {
      setCoustomerQuire([]);
      setIsLoading(false);
    }

    if (conatctUs?.conatctUs?.data?.totalPages) {
      setTotalPages(conatctUs?.conatctUs?.data?.totalPages);
    }
    setShowInitial(true);
    setIsSubmittingFilter(false);
    // eslint-disable-next-line
  }, [conatctUs]);

  const searchByCustomer = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
      localStorage.setItem("customerSearchValue", event?.target.value);
    }
    const parems = {
      token,
      pageNo: currentPage,
      searchBy: searchParam || event?.target.value,
      isSearchDropdown,
    };
    const body = {
      ...parems,
      ...(isSearchDropdown && { isSearchDropdown }),
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getContactUSAllRequest(body));
    }, 10);
  };

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    transactionResetLocalData();
    notificationLocalReset();
    dispatch(updateContactUsEmpty());
  }, []);

  const dropDown = localStorage.getItem("customerDrop");
  useEffect(() => {
    localStorage.setItem("customerSearchValue", searchValue);
  }, [searchValue]);

  useEffect(() => {
    localStorage.setItem("customerCurrentPage", currentPage);
  }, [currentPage]);

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("customerSortValue", value);
      localStorage.setItem("customerDrop", true);
    }
    if (id === "searchDropdown") {
      setIsSearchDropdown(value);
      localStorage.setItem("customerSearchDropdowne", value);
      localStorage.setItem("customerDrop", true);
    }
  };

  useEffect(() => {
    if (isSearchDropdown === "name" || isSearchDropdown === "assigneeName") {
      setDesabledSerchBox(false);
    } else {
      setDesabledSerchBox(true);
    }
  }, [isSearchDropdown]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchValue(localStorage.getItem("customerSearchValue") || "");
  };

  const resetLocalValues = () => {
    localStorage.removeItem("customerSearchValue");
    localStorage.removeItem("customerSortValue");
    localStorage.removeItem("customerDrop");
    localStorage.removeItem("customerSearchDropdowne");
    localStorage.removeItem("customerCurrentPage");
    setCurrentPage(1);
  };

  const refreshCustomerData = () => {
    resetLocalValues();
    const body = {
      pageNo: currentPage,
      token: token,
    };
    dispatch(getContactUSAllRequest(body));
    setShowInitial(true);
    setSearchValue("");
  };

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getContactUSAllRequest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const handleClearFilter = () => {
    setSearchValue("");
    resetLocalValues();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getContactUSAllRequest(body));
    setSortValue("");
    setShowInitial(true);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-start pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-start">
              Customer Support
            </div>

            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>
          {showFilterArea && (
            <div className="flex flex-col md:flex-row justify-between md:items-center mt-3">
              <div className="flex flex-col md:flex-row gap-2 md:items-center">
                <Select
                  id="dropdownOptions"
                  header="Filter By"
                  options={dropdownOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
              </div>
              <div className="flex md:gap-2 md:self-end justify-between mt-3">
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={() => {
                      setCurrentPage(1);
                      handleSubmitFilter();
                    }}
                    displayName={"Submit"}
                    type={"submit"}
                    isLoading={isSubmittingFilter}
                  />
                </span>
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={handleClearFilter}
                    displayName={"Reset"}
                    type={"reset"}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
          <div className="flex justify-between flex-wrap">
            <button
              onClick={() => {
                getCoustomer();
                setSearchValue("");
                setSearchDropdown(true);
                setIsSearchDropdown("");
                localStorage.removeItem("searchDropdown_selectedValue");
                localStorage.removeItem("customerSearchDropdowne");
                // resetLocalValues();
              }}
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>

            <div className="flex justify-end gap-2">
              <div className="-mt-2.5">
                <Select
                  id="searchDropdown"
                  options={serchDropDown}
                  callbackFn={handleSortValues}
                  showInitial={searchDropdown}
                  dropDown={dropDown}
                  selectedValue={isSearchDropdown}
                />
              </div>
              <Search
                label={`Category`}
                placeholder={"Search by customer name, assignee name"}
                name={"Search"}
                searchCallbackfn={searchByCustomer}
                isSearching={isLoading}
                searchValue={searchValue}
                disabled={desabledSerchBox}
              />
            </div>
          </div>
          {customerQuires?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {tableHeaders?.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="py-3 px-4 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {customerQuires?.map((item, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <div>{item.id}</div>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <div>{item.name}</div>
                          </div>
                        </td>

                        <td className="px-4 py-2 text-center">
                          <div>
                            <div>
                              {item.countryCode} {item.mobile}
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <div>{item.emailId}</div>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <div>{item.createDate}</div>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <button
                              className="text-blue-500"
                              onClick={() => {
                                setOpenDescriptionModal(true);
                                setMessage(item.message);
                              }}
                            >
                              show message
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">
                          <div>
                            <button
                              className="text-blue-500"
                              onClick={() => {
                                setOpenNotesModal(true);
                                setNote(item.note);
                                setSelectedId(item.id);
                              }}
                            >
                              Notes
                            </button>
                          </div>
                        </td>
                        <td className="px-3 py-2 text-center">
                          <div>
                            {item.assigneeName ? (
                              <div>{item.assigneeName}</div>
                            ) : (
                              <div>
                                <button
                                  disabled={
                                    !checkAccess("CUSTOMERSUPPORT", "U")
                                  }
                                  className={`hover:bg-gray-100 p-2 rounded-full ${
                                    !checkAccess("CUSTOMERSUPPORT", "U")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    navigate(
                                      {
                                        pathname: `/customerSupport/${item.id}`,
                                      },
                                      { state: item }
                                    );
                                  }}
                                >
                                  <FaPlus className="text-xl text-green-500" />
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-2 text-center">
                          <div>
                            <div
                              className={`text-blue-500 ${
                                !checkAccess("CUSTOMERSUPPORT", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("CUSTOMERSUPPORT", "U")) {
                                  setOpenStatusModel(true);
                                  setSelectedId(item.id);
                                }
                              }}
                            >
                              {item.status}
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-2 text-center">
                          <button
                            disabled={!checkAccess("CUSTOMERSUPPORT", "U")}
                            className={`text-green-600 border p-2 rounded-lg hover:bg-green-200 font-bold ${
                              !checkAccess("CUSTOMERSUPPORT", "U")
                                ? "accessDenied"
                                : "hover:cursor-pointer"
                            }`}
                            onClick={() => {
                              navigate(
                                {
                                  pathname: `/customerSupport/${item.id}`,
                                },
                                { state: item }
                              );
                            }}
                          >
                            {/* Update */}
                            <MdModeEditOutline className="text-2xl" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && !searchValue && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-start flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Data Not Found
            </div>
          )}
        </div>
      </div>

      {openDescriptionModal && (
        <DescriptionModal
          setOpenModal={setOpenDescriptionModal}
          data={message}
          heading={"Description Message"}
        />
      )}

      {openNotesModal && (
        <DescriptionModal
          setOpenModal={setOpenNotesModal}
          data={note}
          heading={"Update Notes"}
          flage={"notes"}
          id={selectedId}
          token={token}
        />
      )}

      {openStatusModel && (
        <DescriptionModal
          setOpenModal={setOpenStatusModel}
          data={message}
          heading={"Update status"}
          flage={"status"}
          token={token}
          id={selectedId}
        />
      )}

      {openAssigneeModal && (
        <DescriptionModal
          setOpenModal={setOpenAssigneeModal}
          flage="Assign"
          token={token}
          assignee={assignee}
          id={selectedId}
          assigneId={assigneId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    conatctUs: state?.ContactUsAllReducer,
    assignee: state?.AssigneeReducer,
    updateContactUs: state?.updateContactUsReducer,
  };
};

export default connect(mapStateToProps)(CoustomerSuport);

// setOpenAssigneeModal
