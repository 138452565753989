
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { TbServerOff } from "react-icons/tb";
import { Pagination } from "../../components/Pagination";
import Loading from "../../components/loading";
import { Search } from "../../components/Search";
import { fetchAnalyticsData } from "../../Utils/fetchAnalyticsData";
import { capitalizeWords } from "../../Utils/capitalizeFn";

const tableHeaders = ["Scenario","Screen Name", "Category name", "Category Name", "Date / time", "Platform"];

const AnalyticsActivity = () => {
  const location = useLocation();
  const { state } = location;
  const [itemData, setItemData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); 
  const [paginatedData, setPaginatedData] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; 

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (state?.tableData) {
  //     const { scenarioMap, uniqueScenarios } = fetchAnalyticsData(
  //       state.tableData
  //     );
  //     const allData = uniqueScenarios?.flatMap((scenario) => {
  //       const platforms = scenarioMap[scenario]?.platforms;
  //       return Object.values(platforms)?.flatMap(
  //         (platformData) => platformData?.eventDetails
  //       );
  //     });
  //     setItemData(allData);
  //     setFilteredData(allData); // Initially set filtered data to all data
  //     paginateData(allData, currentPage);
  //     setTotalPages(Math.ceil(allData?.length / itemsPerPage));
  //   }
  // }, [state]);

  useEffect(() => {
    if (state?.tableData) {
    
      setItemData(state?.tableData);
      setFilteredData(state?.tableData); // Initially set filtered data to all data
      paginateData(state?.tableData, currentPage);
      setTotalPages(Math.ceil(state?.tableData?.length / itemsPerPage));
    }
  }, [state]);
  
   const formatDate = (timestamp) => {
     const date = new Date(timestamp);
     const day = String(date.getDate()).padStart(2, "0");
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const year = date.getFullYear();
     return `${day}/${month}/${year} / ${date.toLocaleTimeString()}`;
   };
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    paginateData(filteredData, newPage);
  };

  const paginateData = (data, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(data?.slice(startIndex, endIndex));
  };

  const searchByUser = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = itemData.filter((item) =>
      item.phone?.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    paginateData(filtered, 1); // Paginate filtered data
  };

  if (loading) {
    return <Loading />;
  }

  console.log(paginatedData,'paginatedData');
  console.log(itemData, "itemData");
  console.log(state?.tableData, "state?.tableData");

  return (
    <div
      className={`bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3`}
    >
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
        <span
          onClick={() => navigate(-1)}
          className="bg-blue-100 cursor-pointer hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
        >
          <MdArrowBack className="text-base" /> Back
        </span>
        <div className="flex justify-between items-center flex-wrap">
          <div className="my-2 text-lg font-bold uppercase">
            Activity Tracker
          </div>
          <div className="flex justify-end gap-2">
            <Search
              label={`Phone`}
              placeholder={"Search by phone"}
              name={"Search"}
              searchCallbackfn={searchByUser}
              isSearching={loading}
              searchValue={searchValue === null ? "" : searchValue}
            />
          </div>
        </div>
        {paginatedData?.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-start"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((event, idx) => (
                    <tr className="bg-white border-b" key={idx}>
                      <td className="px-3 py-2 text-start">
                        {event?.scenario_name}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.screen_name || "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.values?.category_name || "Guest"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.values?.topic_name || "N/A"}
                      </td>
                      <td className="pr-1 py-2 text-start">
                        {event?.datetime
                          ? formatDate(Number(event?.datetime))
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.platform || "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && !searchValue && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={handlePageChange}
                  isLoading={loading}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            <TbServerOff className="text-[2rem] font-bold m-2" />
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};
export default AnalyticsActivity;
 