import * as CONST from "../../Utils/Constants";

export const getQuestionsRequest = (payload) => {
  return {
    type: CONST.GET_ALL_QUWSTIONS_REQUEST,
    payload: payload,
  };
};

export const getQuestionsSuccess = (data) => {
  return {
    type: CONST.GET_ALL_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const getQuestionsFailure = (error) => {
  return {
    type: CONST.GET_ALL_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const createQuestionRequest = (payload) => {
  console.log("payload", payload);
  return {
    type: CONST.CREATE_QUESTIONS_REQUEST,
    payload: payload,
  };
};

export const createQuestionSuccess = (data) => {
  return {
    type: CONST.CREATE_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const createQuestionFailure = (error) => {
  return {
    type: CONST.CREATE_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const getQuestionByIdRequest = (payload) => {
  return {
    type: CONST.GET_QUESTIONS_BY_ID_REQUEST,
    payload: payload,
  };
};

export const getQuestionByIdSuccess = (data) => {
  return {
    type: CONST.GET_QUESTIONS_BY_ID_SUCCESS,
    payload: data,
  };
};

export const deleteQuestionByIdRequest = (payload) => {
  return {
    type: CONST.DELETE_QUESTIONS_BY_ID_REQUEST,
    payload: payload,
  };
};

export const deleteQuestionByIdSuccess = (data) => {
  return {
    type: CONST.DELETE_QUESTIONS_BY_ID_SUCCESS,
    payload: data,
  };
};

export const updateQuestionRequest = (payload) => {
  return {
    type: CONST.UPDATE_QUESTIONS_REQUEST,
    payload: payload,
  };
};

export const updateQuestionSuccess = (data) => {
  return {
    type: CONST.UPDATE_QUESTIONS_SUCCESS,
    payload: data,
  };
};
