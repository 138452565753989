import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Search } from "../components/Search";
import { DeleteModal } from "../components/modal/DeleteModal";
import { AddNewModal } from "../components/modal/AddNewModal";
import { SECRET_KEY } from "../Utils/Constants";
import CryptoJS from "crypto-js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import defaultImg from "../assets/images/defaultImage.png";
import { TbServerOff } from "react-icons/tb";
import {
  MdDelete,
  MdModeEditOutline,
  MdKeyboardArrowRight,
  MdArrowBack,
} from "react-icons/md";
import {
  ActiveTopicRequest,
  AddTopicRequest,
  DeleteTopicRequest,
  UpdateTopicRequest,
  getTopicsByCategoryIdRequest,
  searchTopicsRequest,
} from "../Actions";
import { Pagination } from "../components/Pagination";
import useTopicAlerts from "../components/alertHelper/useTopicAlerts";
import useResetTopicReducers from "../components/reduxHelper/resetReducers/useResetTopicReducers";
import { SpinnerLoader } from "../components/Loaders/SpinnerLoader";
import { ConfirmationModal } from "../components/modal/ConfirmationModal";
import Loading from "../components/loading";
import { checkAccess } from "../Utils/RoleBasedRoute";

const Topic = (props) => {
  const {
    TopicsByCategoryId,
    activeTopics,
    addTopic,
    deleteTopic,
    searchTopics,
    updatedTopic,
    // logInData,
    // AllCategories,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [topicArr, setTopicArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editTopicData, setEditTopicData] = useState({});
  const [openTopicAddModal, setOpenTopicAddModal] = useState(false);
  const [openEditTopicModal, setOpenEditTopicModal] = useState(false);
  const [openDeleteTopicModal, setOpenDeleteTopicModal] = useState(false);
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [deleteTopicData, setDeleteTopicData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isAddingTopic, setIsAddingTopic] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const timerRef = useRef(null);
  const [openEnableDisableModal, setOpenEnableDisableModal] = useState(false);
  const [operation, setOperation] = useState("");
  const [QuizData, setQuizData] = useState();

  const [searchParams] = useSearchParams();
  const categoryIdparam = searchParams.get("categoryId");
  const categoryNameparam = searchParams.get("categoryName");

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  useEffect(() => {
    setCategoryDisabled(false);
    const body = {
      token: token,
      pageNo: currentPage,
      categoryId: categoryIdparam,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  }, [token, currentPage, deleteTopic, addTopic, updatedTopic, activeTopics]);

  // Reset topic reducer state while unmounting
  useEffect(() => {
    return () => {
      resetHook.reset();
    };
  }, []);
  // Reset topic reducer state while unmounting
  useEffect(() => {
    setDeleteLoading(deleteTopic?.loading);
  }, [deleteTopic?.loading]);

  useEffect(() => {
    alertHook.showAlert(addTopic, updatedTopic, deleteTopic);
  }, [addTopic, updatedTopic, deleteTopic]);

  const alertHook = useTopicAlerts();
  const resetHook = useResetTopicReducers();

  useEffect(() => {
    if (searchValue) searchByTopicName(null, searchValue);
  }, [updatedTopic, activeTopics]);

  useEffect(() => {
    if (searchTopics?.loading || TopicsByCategoryId?.loading) {
      setIsLoading(true);
      setError(false);
    }
    if (searchTopics?.error) {
      setIsLoading(false);
      setError(true);
    }
    if (TopicsByCategoryId?.topics?.topics) {
      setTopicArr(TopicsByCategoryId?.topics?.topics);
      setIsLoading(false);
      setError(false);
    }

    if (activeTopics?.ActiveTopic?.statusCode === 409) {
      // setCategoryDisabled(true);
    }
    if (searchValue && searchTopics?.searchTopics === 404) {
      setTopicArr([]);
      setIsLoading(false);
      setError(false);
    }
    if (searchValue && searchTopics?.searchTopics?.data) {
      setTopicArr(searchTopics?.searchTopics?.data);
      setIsLoading(false);
      setError(false);
    }
    if (searchValue && searchTopics?.searchTopics.data !== undefined) {
      setTopicArr(searchTopics?.searchTopics.data);
    }

    if (TopicsByCategoryId?.topics?.totalPages) {
      setTotalPages(TopicsByCategoryId?.topics?.totalPages);
    }
    // if(TopicsByCategoryId?.currentPage) setCurrentPage(TopicsByCategoryId?.currentPage)
  }, [TopicsByCategoryId, searchTopics, deleteTopic, activeTopics]);

  const handleActive = (item, boolean) => {
    setOpenEnableDisableModal(true);
    setOperation(`${boolean ? "Enable" : "Disable"}`);
    setQuizData({ item, boolean });
  };

  const handleEnableDisable = () => {
    const body = {
      token: token,
      topicId: QuizData?.item?.id,
      enableTopic: QuizData?.boolean,
    };
    dispatch(ActiveTopicRequest(body));
    setOperation("");
    setQuizData(null);
  };

  const handleAddTopic = async (topicImage, topicName, topicNameHindi) => {
    setIsAddingTopic(true);
    const body = {
      token: token,
      categoryId: categoryIdparam,
      nameEng: topicName,
      nameHin:topicNameHindi,
      image: topicImage,
    };
    dispatch(AddTopicRequest(body));
    setTimeout(() => {
      setIsAddingTopic(false);
    }, 2000);
  };

  const handleEditTopic = (topicImage, topicName, topicNameHindi, topicId) => {
    setLoadingImages((prevLoadingImages) => [...prevLoadingImages, topicId]);
    const body = {
      categoryId: categoryIdparam,
      topicId: topicId,
      token: token,
      nameEng: topicName,
      nameHin:topicNameHindi,
      image: topicImage,
    };
    dispatch(UpdateTopicRequest(body));
    setTimeout(() => {
      setLoadingImages((prevLoadingImages) =>
        prevLoadingImages.filter((id) => id !== topicId)
      );
    }, 2000);
  };

  const handleDeleteTopic = (topicId) => {
    const body = {
      token: token,
      categoryId: categoryIdparam,
      topicId: topicId,
    };
    setIsLoading(true);

    dispatch(DeleteTopicRequest(body));
  };

  const searchByTopicName = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event.target.value);
    }
    const body = {
      token: token,
      searchValue: searchParam || event.target.value,
      categoryId: categoryIdparam,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(searchTopicsRequest(body));
    }, 10);
  };

  if (isLoading || deleteLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] relative">
          <div className="flex justify-start cursor-pointer">
            <span
              onClick={() => navigate(-1)}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 py-2">
            <div className="font-bold uppercase text-lg flex justify-center items-center">
              <div className="whitespace-nowrap flex items-center">
                <span> {categoryNameparam}</span> <MdKeyboardArrowRight />{" "}
                Topics
              </div>
            </div>
            <div className="flex justify-between items-stretch gap-2">
              <Search
                label={`Topic`}
                placeholder={"Search By Name or ID"}
                name={"Search"}
                searchCallbackfn={searchByTopicName}
                isSearching={searchTopics?.loading}
              />
              <button
                onClick={() => setOpenTopicAddModal(true)}
                disabled={!checkAccess("TOPIC", "C") || isAddingTopic}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("CATEGORY", "C") ? "accessDenied" : ""
                }`}
              >
                {isAddingTopic ? (
                  <span className="ml-2 w-[92px]">
                    <SpinnerLoader size="xs" />
                  </span>
                ) : (
                  <>
                    Add Topic
                    <FaPlus />
                  </>
                )}
              </button>
            </div>
          </div>
          {categoryDisabled && (
            <div className="text-red-500 pb-3 text-sm">{`"${categoryNameparam}" category is disabled. Please enable category first`}</div>
          )}
          {topicArr?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {tableHeaders.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {topicArr?.map((item, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-6 py-2 text-center">{item.id}</td>
                        <td className="px-6 py-2 pt-3 text-center flex flex-col">
                          <span>{item?.nameEng}</span>
                          <span>{item?.nameHin || "hindi"}</span>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="flex justify-center">
                            {loadingImages.includes(item.id) ? (
                              <SpinnerLoader size="sm" />
                            ) : (
                              <img
                                src={
                                  item.imagePath ? item.imagePath : defaultImg
                                }
                                className="w-15 h-10"
                                alt=""
                              />
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          {item.enabled ? (
                            <button
                              onClick={() => {
                                handleActive(item, false);
                              }}
                              type="button"
                              disabled={!checkAccess("TOPIC", "U")}
                              className={`inline-flex items-center px-5 border-green-200 border-2 py-2.5 text-sm font-medium text-center text-green-700 rounded-lg hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-100 ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "text-green-700 hover:bg-green-50"
                              }`}
                            >
                              Enabled
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleActive(item, true);
                              }}
                              type="button"
                              disabled={!checkAccess("TOPIC", "U")}
                              className={`inline-flex items-center border-2 border-red-200 px-5 py-2.5 text-sm font-medium text-center text-red-700 rounded-lg hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-100 ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "text-red-700 hover:bg-red-50"
                              }`}
                            >
                              Disabled
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="flex gap-x-3 justify-center">
                            <div
                              onClick={() => {
                                if (checkAccess("TOPIC", "U")) {
                                  setEditTopicData({
                                    id: item.id,
                                    name: item.nameEng,
                                    nameHin:item?.nameHin
                                  });
                                  setOpenEditTopicModal(true);
                                }
                              }}
                              className={`hover:bg-gray-100  p-1 rounded-full ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                            <div
                              onClick={() => {
                                if (checkAccess("TOPIC", "U")) {
                                  setDeleteTopicData({
                                    id: item.id,
                                    name: item.name,
                                  });
                                  setSearchValue("");
                                  setOpenDeleteTopicModal(true);
                                }
                              }}
                              className={`hover:bg-gray-100  p-1 rounded-full ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              <MdDelete className="text-2xl" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={TopicsByCategoryId?.loading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Topics Not Found
            </div>
          )}
        </div>
      </div>
      {openTopicAddModal && (
        <AddNewModal
          heading={"Add Topic"}
          nameLabel={"Name of Topic"}
          hindiLabel={"विषय का नाम"}
          submitLabel={"Add New Topic"}
          submitLabelsm={"Add Topic"}
          handleSubmit={handleAddTopic}
          setOpenModal={setOpenTopicAddModal}
          isLoading={isLoading}
          flage={"editTopic"}
          note={true}
        />
      )}

      {openEditTopicModal && (
        <AddNewModal
          heading={"Edit Topic"}
          nameLabel={"Name of Topic"}
          hindiLabel={"विषय का नाम"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={handleEditTopic}
          openModal={openEditTopicModal}
          setOpenModal={setOpenEditTopicModal}
          data={editTopicData}
          flage={"editTopic"}
          note={true}
        />
      )}

      {openDeleteTopicModal && (
        <DeleteModal
          data={deleteTopicData}
          handleDelete={handleDeleteTopic}
          setOpenDeleteModal={setOpenDeleteTopicModal}
        />
      )}

      {openEnableDisableModal && (
        <ConfirmationModal
          operation={operation}
          data={QuizData?.item?.name}
          handleCancel={handleEnableDisable}
          setOpenCancelModal={setOpenEnableDisableModal}
          source={"Topic"}
          message={
            "If you disable a topic then all its quizzes will be disabled, are you sure you want to continue?"
          }
        />
      )}
    </>
  );
};

const tableHeaders = ["Id", "Topic", "Image", "Status", "Actions"];

const mapStateToProps = (state) => {
  return {
    logInData: state?.LogInReducer?.user,
    addTopic: state?.AddTopicReducers,
    AllCategories: state?.AllCategoriesReducer?.AllCategories,
    deleteTopic: state?.DeleteTopicReducer,
    updatedTopic: state?.UpdateTopicReducer,
    activeTopics: state?.ActiveTopicReducer,
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
    searchTopics: state?.SearchTopicsReducer,
  };
};

const mapDispatchToProps = () => {
  return {
    // loginRequest, // Map the loginRequest action creator to props
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topic);
