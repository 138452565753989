import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";
import { toast } from "react-toastify";

export const AddNewModal = ({
  heading,
  nameLabel,
  hindiLabel,
  submitLabel,
  submitLabelsm,
  handleSubmit,
  setOpenModal,
  data,
  categoryId,
  isLoading,
  flage,
  secondNameLable,
  note,
}) => {
  const [image, setImage] = useState(null);
  const [name, setName] = useState(data?.name ? data?.name : "");
  const [hindiName,setHindiName] = useState(data?.nameHin?data?.nameHin:"")
  const [secondInput, setSecondInput] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleNameChange = (event) => {
    let value = event.target.value;
    setName(value);

    fetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
        value
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data[0] && data[0][0] && data[0][0][0]) {
          setHindiName(data[0][0][0]);
        }
      })
      .catch((err) => console.error(err));

  };
   const handleHindiNameChange = (event) => {
     setHindiName(event.target.value);
   };

  const handleSecondInput = (event) => {
    const confirmPassword = event.target.value;
    setSecondInput(confirmPassword);

    // Check if passwords match
    if (name !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  return (
    <div
      className="fixed h-[100vh] w-full top-0 right-0 z-50 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1" aria-hidden="true">
        <div className="relative w-[300px] sm:w-[420px]">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-3 md:mb-4 text-xl font-medium text-gray-900">
                {heading}
              </h3>
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  console.log("Handle submit running")
                  e.preventDefault();
                  handleSubmit(image, name,hindiName, data?.id);
                  setOpenModal((prev) => !prev);
                }}
              >
                <div>
                  {categoryId?.length || flage === "editCategory" ? (
                    <>
                      <label
                        className="block mb-1 md:mb-2 text-sm font-medium text-gray-900"
                        htmlFor="image"
                      >
                        Choose an Image
                      </label>
                      {note && (
                        <div className="text-[0.7rem] text-red-900">
                          <div className="mb-2">
                            <div>
                              • The image should be in <strong>PNG</strong>{" "}
                              format (.png)
                            </div>
                            <div>
                              • The image dimensions (height & width) should be{" "}
                              <strong>200x200 px</strong>
                            </div>
                            <div>
                              • The image should have a{" "}
                              <strong>transparent/white</strong> background
                            </div>
                            <div>
                              • The image icon color should be{" "}
                              <strong>Blue</strong>
                            </div>
                          </div>
                        </div>
                      )}

                      <input
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                        type="file"
                        onChange={handleImageChange}
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      {flage === "editTopic" && (
                        <>
                          <label
                            className="block mb-1 md:mb-2 text-sm font-medium text-gray-900"
                            htmlFor="image"
                          >
                            Choose an Image
                          </label>
                          {note && (
                            <div className="text-[0.7rem] text-red-900">
                              <div className="mb-2">
                                <div>
                                  • The image should be in <strong>PNG</strong>{" "}
                                  format (.png)
                                </div>
                                <div>
                                  • The image dimensions (height & width) should
                                  be <strong>200x200 px</strong>
                                </div>
                                <div>
                                  • The image should have a{" "}
                                  <strong>transparent/white</strong> background
                                </div>
                                <div>
                                  • The image icon color should be{" "}
                                  <strong>Blue</strong>
                                </div>
                              </div>
                            </div>
                          )}

                          <input
                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            type="file"
                            onChange={handleImageChange}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                  >
                    {nameLabel}
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder={data?.name && `Current Name : ${data.name}`}
                    value={name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1"
                    onChange={handleNameChange}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                  >
                    {hindiLabel}
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder={
                      data?.hindiName && `Current Name : ${data.hindiName}`
                    }
                    value={hindiName}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1"
                    onChange={handleHindiNameChange}
                    required
                  />
                </div>
                {flage === "resetPassword" && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                    >
                      {secondNameLable}
                    </label>
                    <input
                      type="password"
                      name="name"
                      placeholder={data?.name && `Current Name : ${data.name}`}
                      value={secondInput}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1"
                      onChange={handleSecondInput}
                      required
                    />
                  </div>
                )}
                <button
                  type="submit"
                  className="w-full text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  // disabled={passwordError !== ""}
                >
                  {isLoading ? (
                    <span className="ml-2">
                      <SpinnerLoader size="xs" />
                    </span>
                  ) : (
                    <>
                      <span className="hidden md:inline-block">
                        {submitLabel}
                      </span>
                      <span className="inline-block md:hidden">
                        {submitLabelsm}
                      </span>
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
