import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { Select } from "../select/SelectNew";
import { Button } from "../ui-elements/Button";
import { DeleteModal } from "../modal/DeleteModal";
import {
  CategoriesRequest,
  TopicsRequest,
  getQuestionsRequest,
  getQuestionByIdRequest,
  deleteQuestionByIdRequest,
  getTopicsByCategoryIdRequest,
  topicsByCategoryIdReset,
  deleteMultipulQuestionsRequest,
} from "../../Actions";
import { SECRET_KEY } from "../../Utils/Constants";
import CryptoJS from "crypto-js";
// import { useNavigate } from "react-router";
import Table from "../table";
import {
  levelOptions,
  notificationLocalReset,
  QuestionsTypeOption,
  resetCustomerSupport,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../constant";
import { Pagination } from "../Pagination";
import { toast } from "react-toastify";
import Loading from "../loading";
import GetExcelFile from "../../Utils/exportQuestionExcel";
import { useLocation, useNavigate } from "react-router";
import ManageQuestionTable from "./ManageQuestionTable";
import { MdArrowBack } from "react-icons/md";
const tableHeaders = [
  "Question Id",
  "Discription",
  "Option English",
  "Option Hindi",
  "Answer English & Hindi",
  "Level",
  "Utilization Score",
  "Actions",
];

const ManageQuestion = ({
  allTopicsProp,
  allCategoriesProp,
  questions,
  TopicsByCategoryId,
  deleteMultipulQuestions,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const stateItem = location.state;
  const navigate = useNavigate();

  console.log(stateItem, "state item of manage question click");
  // const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(
  //   parseInt(localStorage.getItem("qcurrentPage")) || 1
  // );
  const [questionsArray, setQuestionsArray] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("qsearchValue") || ""
  );
  const [filterByTopic, setFilterByTopic] = useState(
    localStorage.getItem("qTopicFilter") || ""
  );
  const [filterByLevel, setFilterByLevel] = useState(
    localStorage.getItem("qLevelFilter") || ""
  );
  const [filterByQuestionType, setFilterByQuestionType] = useState(
    localStorage.getItem("qTypeFilter") || ""
  );
  const [filterByCategory, setFilterByCategory] = useState(
    localStorage.getItem("qCategoryFilter") || ""
  );
  const [openDeleteQuizModal, setOpenDeleteModal] = useState(false);
  const [deleteQuizData, setDeleteData] = useState({});
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [showInitial, setShowInitial] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [catChange, setCategoryChange] = useState(0);
  const [deleteQuestionData, setDeleteQuestionData] = useState([]);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const timerRef = useRef(null);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const { searchQuestions, deleteMessage } = questions;

  const categoryOptions = allCategories?.map((item) => ({
    id: item.id,
    name: item.nameEng,
  }));
  console.log(allCategories, "allCategories");

  const topic = TopicsByCategoryId?.topics?.topics;

  const topicData = topic
    ?.filter((topic) => topic.enabled)
    .map((item) => ({
      id: item.id,
      name: item.nameEng,
    }));

  const getData = () => {
    const body = {
      token,
      filterByTopic,
      filterByCategory,
      filterByLevel,
      filterByQuestionType,
      topicId:stateItem?.topicId,
      categoryId:stateItem?.categoryId,
      pageSize:6,
      pageNo: currentPage,
      filterBy:'CASH'
    };
    dispatch(getQuestionsRequest(body));
    dispatch(TopicsRequest({ token }));
    dispatch(CategoriesRequest({ token }));
    setShowInitial(true);
  };

  useEffect(() => {
    if (questions?.loading) {
      setIsLoading(true);
    }
    if (questions?.error) {
      setIsLoading(false);
    }

    if (questions?.questions?.data?.questions) {
      setQuestionsArray(questions?.questions?.data?.questions);
      setIsLoading(false);
    }

    if (searchValue && searchQuestions?.data) {
      setQuestionsArray([searchQuestions?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (searchQuestions?.data?.statusCode === 404 ||
        searchQuestions?.data?.statusCode === 400)
    ) {
      setQuestionsArray([]);
      setIsLoading(false);
    }

    if (questions?.questions?.data?.totalPages) {
      setTotalPages(questions?.questions?.data.totalPages);
    }
    setIsSubmittingFilter(false);
    localStorage.setItem("qsearchValue", searchValue);

    // eslint-disable-next-line
  }, [questions, searchQuestions]);

  useEffect(() => {
    const topics = allTopicsProp?.Topics?.topics;
    topics && setAllTopics(topics);
  }, [allTopicsProp]);

  useEffect(() => {
    const categories = allCategoriesProp?.AllCategories?.categories;
    categories && setAllCategories(categories);
  }, [allCategoriesProp]);

  const searchByQuestionId = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event.target.value);
      localStorage.setItem("qsearchValue", event?.target.value);
    }

    const body = {
      token,
      questionId: searchParam || event.target.value,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getQuestionByIdRequest(body));
    }, 10);
  };

  const getTopicsByCategoryId = (cat) => {
    const body = {
      token: token,
      categoryId: cat,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchValue(localStorage.getItem("qsearchValue") || "");
  };

  const handleSortValues = (id, value) => {
    if (id === "topicOptions") {
      setFilterByTopic(value);
      localStorage.setItem("qTopicFilter", value);
    }
    if (id === "categoryOptions") {
      getTopicsByCategoryId(value);
      setFilterByCategory(value);
      localStorage.removeItem("qTopicFilter");
      localStorage.removeItem("topicOptions_selectedValue");
      setCategoryChange((prev) => prev + 1);
      localStorage.setItem("qCategoryFilter", value);
      localStorage.setItem("questionDrop", true);
    }
    if (id === "levelOptions") {
      setFilterByLevel(value);
      localStorage.setItem("qLevelFilter", value);
    }
    if (id === "questionTypeOptions") {
      setFilterByQuestionType(value);
      localStorage.setItem("qTypeFilter", value);
    }
  };

  useEffect(() => {
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    setApplyFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      filterByTopic,
      filterByCategory,
      filterByLevel,
      categoryId:stateItem?.categoryId,
      topicId:stateItem?.topicId,
      filterBy:'CASH',
      filterByQuestionType,
    };
    const body = {
      ...params,
      ...(filterByTopic && { filterByTopic }),
      ...(filterByCategory && { filterByCategory }),
      ...(filterByLevel && { filterByLevel }),
      ...(filterByQuestionType && { filterByQuestionType }),
    };
    dispatch(getQuestionsRequest(body));
    setShowInitial(false);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("levelOptions_selectedValue");
      localStorage.removeItem("questionTypeOptions_selectedValue");
      localStorage.removeItem("qTypeFilter");
    };
  }, []);

  useEffect(() => {
    getData();
    localStorage.setItem("qcurrentPage", currentPage);
    if (applyFilter) {
      handleSubmitFilter();
    }
    // eslint-disable-next-line
  }, [currentPage, deleteMessage]);

  useEffect(() => {
    getData();
    const filterByCategory = localStorage.getItem("qCategoryFilter");
    const filterByTopic = localStorage.getItem("qTopicFilter");
    const filterByLavel = localStorage.getItem("qLevelFilter");
    const filterByQuestionType = localStorage.getItem("qTypeFilter");
    setSearchValue(localStorage.getItem("qsearchValue"));
    if (filterByCategory) {
      setFilterByCategory(filterByCategory);
    }
    if (filterByLavel) {
      setFilterByLevel(filterByLavel);
    }
    if (filterByTopic) {
      setFilterByTopic(filterByTopic);
    }
    if (filterByQuestionType) {
      setFilterByTopic(filterByTopic);
    }
  }, []);

  const dropDown = localStorage.getItem("questionDrop");

  useEffect(() => {
    getData();
  }, [deleteMultipulQuestions, questions?.newQuestion]);

  const resetFilterInQuestions = () => {
    localStorage.removeItem("qCategoryFilter");
    localStorage.removeItem("qTopicFilter");
    localStorage.removeItem("qLevelFilter");
    localStorage.removeItem("qTypeFilter");
    localStorage.removeItem("qcurrentPage");
    localStorage.removeItem("questionDrop");
    setCurrentPage(1);
  };

  const handleClearFilter = () => {
    resetFilterInQuestions();
    const body = {
      token,
      pageNo: currentPage,
      categoryId:stateItem?.categoryId,
      topicId:stateItem?.topicId,
      filterBy:'FREE'
    };
    dispatch(getQuestionsRequest(body));
    setIsSubmittingFilter(false);
    setApplyFilter(false);
    setFilterByTopic("");
    setFilterByLevel("");
    setFilterByCategory("");
    dispatch(topicsByCategoryIdReset());
    setShowInitial(true);
  };

  const handleDeleteQuestion = (id) => {
    const body = {
      token: token,
      id,
    };
    dispatch(deleteQuestionByIdRequest(body));
  };

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const handleCheckboxChange = (id) => {
    if (checkedBoxes?.includes(id)) {
      setCheckedBoxes(checkedBoxes?.filter((selectedId) => selectedId !== id));
    } else {
      setCheckedBoxes([...checkedBoxes, id]);
    }
  };

  const clearSelectedQuestions = () => {
    setCheckedBoxes([]);
  };

  const handleCheckboxId = () => {
    if (checkedBoxes.length) {
      const body = {
        data: {
          questionIds: checkedBoxes,
        },
        token: token,
      };
      dispatch(deleteMultipulQuestionsRequest(body));
      clearSelectedQuestions();
    } else {
      toast.info("Please Select questions for delete");
    }
  };

  const handleQuestionExport = () => {
    GetExcelFile({
      token,
      filterBy: filterByQuestionType,
      difficultCount: 0,
      mediumCount: 0,
      easyCount: 0,
      level: filterByLevel,
      topicId: filterByTopic,
      categoryId: filterByCategory,
      sortOrder: 0,
      sortBy: 0,
      searchBy: "id",
      searchValue: searchValue,
      allCategories,
      allTopics,
    });
  };

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[80vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-start cursor-pointer">
            <span
              onClick={() => {
                navigate(-1)
              }}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="font-bold uppercase text-lg text-center">
              Manage Questions
            </div>

            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>
          {showFilterArea && (
            <div className="flex w-full flex-col md:flex-row justify-between md:items-center mt-3">
              <div className="flex w-full  flex-col md:flex-row gap-2 md:items-center">
                <Select
                  id="categoryOptions"
                  header="Category Name"
                  initialValue={stateItem?.categoryNameEng}
                  selectedValue={stateItem?.categoryNameEng}
                  options={categoryOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                  disabled={true}
                />
                <Select
                  id="topicOptions"
                  header="Topic Filters"
                  initialValue={stateItem?.topicNameEng}
                  selectedValue={stateItem?.topicNameEng}
                    options={topicData}
                  callbackFn={handleSortValues}
                  showInitial={catChange}
                  dropDown={dropDown}
                  disabled={true}
                />
                {/* <div className="flex gap-2 ">
                  <div className="flex mb-3 flex-col gap-2 p-2 justify-center items-center">
                    <span className=" font-semibold text-lg">Easy </span>
                    <span className=" font-semibold">
                      {stateItem?.easyCount}
                    </span>
                  </div>
                  <div className="flex mb-3 flex-col gap-2 p-2 justify-center items-center">
                    <span className=" font-semibold text-lg">Medium </span>
                    <span className=" font-semibold">
                      {stateItem?.mediumCount}
                    </span>
                  </div>
                  <div className="flex mb-3 flex-col gap-2 p-2 justify-center items-center">
                    <span className=" font-semibold text-lg">Difficult</span>
                    <span className=" font-semibold">
                      {stateItem?.difficultCount}
                    </span>
                  </div>
                </div> */}
                <Select
                  id="levelOptions"
                  header="Sort Level Filters"
                  initialValue="Select Level"
                  options={levelOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
                <div className="flex gap-3 md:pl-[50px] w-full  self-end float-end md:items-end md:justify-end md:mt-7 ">
                  <span onClick={handleFilterAreaView}>
                    <Button
                      callbackFn={() => {
                        setCurrentPage(1);
                        handleSubmitFilter();
                      }}
                      displayName={"Submit"}
                      type={"submit"}
                      isLoading={isSubmittingFilter}
                      handleSubmitFilter={handleSubmitFilter}
                    />
                  </span>
                  <span onClick={handleFilterAreaView}>
                    <Button
                      callbackFn={handleClearFilter}
                      displayName={"Reset"}
                      type={"reset"}
                    />
                  </span>
                </div>
                {/* <Select
                  id="questionTypeOptions"
                  header="Question Type Filter"
                  initialValue="Select Type"
                  options={QuestionsTypeOption}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                /> */}
              </div>
            </div>
          )}
        </div>

        <ManageQuestionTable
          isLoading={isLoading}
          tableHeaders={tableHeaders}
          itemsArray={questionsArray}
          isSubmittingFilter={applyFilter}
          totalPages={totalPages}
          setOpenDeleteModal={setOpenDeleteModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          setDeleteQuestionData={setDeleteQuestionData}
          setDeleteData={setDeleteData}
          path={"/questionPool/addQuestion"}
          searchByQuestionId={searchByQuestionId}
          searchValue={searchValue}
          handleCheckboxChange={handleCheckboxChange}
          checkedBoxes={checkedBoxes}
          handleCheckboxId={handleCheckboxId}
          setCheckedBoxes={setCheckedBoxes}
          countOfQuestion={
            searchValue
              ? questionsArray.length
              : questions?.questions?.data?.totalRecords
          }
          handleQuestionExport={handleQuestionExport}
          userId= {stateItem?.userId}
          quizId= {stateItem?.quizId}
          token={token}
        />

        {Boolean(totalPages > 1 && !searchValue && questionsArray.length) && (
          <div className="flex justify-end py-4 ">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
      {openDeleteQuizModal && (
        <DeleteModal
          data={deleteQuizData}
          handleDelete={handleDeleteQuestion}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
      {openDeleteQuestionModal && (
        <DeleteModal
          data={deleteQuestionData}
          handleDelete={handleCheckboxId}
          setOpenDeleteModal={setOpenDeleteQuestionModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    questions: state?.questionsReducer,
    allTopicsProp: state?.TopicReducer,
    allCategoriesProp: state?.AllCategoriesReducer,
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
    deleteMultipulQuestions:
      state?.deleteMultipulQuestionsReducer?.deleteMultipulQuestions,
  };
};

export default connect(mapStateToProps)(ManageQuestion);
